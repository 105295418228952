import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import TypeFamily from '../../../components/TypeFamily';
import ExampleWrapper from '../../../components/ExampleWrapper';
import TypeScale from '../../../components/TypeScale';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
  Typography can help create clear hierarchies, organize information, and guide users through a product or experience.
    </PageDescription>
    <h2>{`Typeface: Roboto and Decima`}</h2>
    <ExampleWrapper mdxType="ExampleWrapper">
  <TypeFamily mdxType="TypeFamily">Roboto</TypeFamily>
  <TypeFamily mono mdxType="TypeFamily">Decima</TypeFamily>
    </ExampleWrapper>
    <h3>{`Sans-serif font stack`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`  font-family: Roboto, sans-serif;
`}</code></pre>
    <h3>{`Mono font stack`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`  font-family: DecimaMonoProLt, mono,  monospace;
`}</code></pre>
    <h2>{`Type Scale`}</h2>
    <ExampleWrapper mdxType="ExampleWrapper">
  <TypeScale size={0.625} highlight mdxType="TypeScale">xxxs — 0.625rem — 10px</TypeScale>
  <TypeScale size={0.625} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
  <TypeScale size={0.6875} highlight mdxType="TypeScale">xxs — 0.6875rem — 11px</TypeScale>
  <TypeScale size={0.6875} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
  <TypeScale size={0.75} highlight mdxType="TypeScale">xs — 0.75rem — 12px</TypeScale>
  <TypeScale size={0.75} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
  <TypeScale size={0.8125} highlight mdxType="TypeScale">s — 0.8125rem — 13px</TypeScale>
  <TypeScale size={0.8125} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
  <TypeScale size={0.875} highlight mdxType="TypeScale">m — 0.875rem — 14px</TypeScale>
  <TypeScale size={0.875} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
  <TypeScale size={1} highlight mdxType="TypeScale">l — 1rem — 16px</TypeScale>
  <TypeScale size={1} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
  <TypeScale size={1.25} highlight mdxType="TypeScale">xl — 1.125rem — 20px</TypeScale>
  <TypeScale size={1.25} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
  <TypeScale size={1.5} highlight mdxType="TypeScale">xxl — 1.5rem — 24px</TypeScale>
  <TypeScale size={1.5} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
  <TypeScale size={2} highlight mdxType="TypeScale">xxxl — 2rem — 32px</TypeScale>
  <TypeScale size={2} mdxType="TypeScale">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio temporibus numquam incidunt, nam corrupti quo quaerat, non iste reprehenderit similique odit ipsam eveniet inventore perferendis fuga maiores tenetur rerum aut.</TypeScale>
    </ExampleWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      